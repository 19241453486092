import type { Json } from './types';

export type ClientResponse =
  | {
      success: false;
      error: { status: number; statusText: string; message: string };
    }
  | { success: true; payload: Json };

export interface Client {
  /// Client fetch
  fetch: (path: string, payload: Json) => Promise<ClientResponse>;
  /// Report client errors, ether network or parsing
  reportError: (error: unknown) => void;
}

declare global {
  interface Window {
    _IncidentCoreLibSingletonClient?: Client;
  }
}

export function getClient(): Client {
  const instance = window._IncidentCoreLibSingletonClient;
  if (instance == null) {
    throw new Error('Client instance not set. call setClient before using any API that requires the client. ');
  }
  return instance;
}

export function setClient(client: Client): void {
  const instance = window._IncidentCoreLibSingletonClient;
  if (instance == null) {
    window._IncidentCoreLibSingletonClient = client;
  } else {
    // we still update the singleton, in case of live updating etc
    window._IncidentCoreLibSingletonClient = client;
    console.error('Client instance already set. Singleton Client can only be set once.');
  }
}
