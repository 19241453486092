import * as z from 'zod';

const LiteralSchema = z.union([z.string(), z.number(), z.boolean(), z.null()]);

type JsonLiteral = z.infer<typeof LiteralSchema>;
export interface JsonObject {
  [key: string]: JsonJson;
}
type JsonJson = JsonLiteral | JsonObject | readonly JsonJson[];

export const JsonSchema: z.ZodType<JsonJson> = z.lazy(() =>
  z.union([LiteralSchema, z.array(JsonSchema).readonly(), z.record(JsonSchema)])
);

export type Json = z.infer<typeof JsonSchema>;

/**
 * Returns true if the given value is a valid JSON string, and sets the type.
 */
export function isJsonString(something?: Json): something is string {
  return typeof something === 'string';
}

/**
 * Returns true if the given value is a valid JSON number, and sets the type
 */
export function isJsonNumber(something?: Json): something is number {
  return typeof something === 'number';
}

/**
 * Returns true if the given value is a valid JSON boolean, and sets the type
 */
export function isJsonBoolean(something?: Json): something is boolean {
  return typeof something === 'boolean';
}

/**
 * Returns true if the given value is a valid JSON array, and sets the type
 */
export function isJsonArray(something?: Json): something is Json[] {
  return Array.isArray(something);
}

/**
 * Is a json array of elements of the given type
 */
export function isJsonArrayOf<T extends Json>(
  isElementType: (s?: Json) => s is T,
  something?: Json
): something is readonly T[] {
  // ideally we'd use Array.all but it's expensive. check only first element for now.
  return Array.isArray(something) && (something.length === 0 || isElementType(something?.[0]));
}

export function isJsonPrimitive(something?: Json): something is boolean | number | string | null {
  // Purposefully not ==
  if (something === null) {
    return true;
  }
  switch (typeof something) {
    case 'string':
    case 'number':
    case 'boolean':
      return true;
    default:
      return false;
  }
}

export function isJsonObject(something?: Json): something is JsonObject {
  return !isJsonPrimitive(something) && !isJsonArray(something);
}
export function maybeArray<T extends Json>(
  elementType: (s?: Json) => s is T,
  path: number | string,
  something?: Json | undefined
): readonly T[] | undefined {
  if (isJsonArrayOf(elementType, something) && typeof path === 'number') {
    return something;
  } else if (isJsonObject(something)) {
    const sp = something[path];
    if (isJsonArrayOf(elementType, sp)) {
      return sp;
    }
  }
  return undefined;
}

export function maybeJsonField<T extends Json>(
  elementType: (s?: Json) => s is T,
  path: number | string,
  something?: Json | undefined
): T | undefined {
  if (isJsonArrayOf(elementType, something) && typeof path === 'number') {
    return something[path];
  }

  if (isJsonObject(something)) {
    const sp = something[path];
    if (elementType(sp)) {
      return sp;
    }
  }

  return undefined;
}

/**
 * Decode json using a zod schema
 */
export async function decodeJson<T extends { error?: string | null }>(
  something: Json,
  schema: z.Schema<T, z.ZodTypeDef, Json>
): Promise<APIResponse<T>> {
  const result = await schema.safeParseAsync(something);
  if (result.success) {
    const { error } = result.data;
    if (error != null && error?.length > 0) {
      return {
        success: false,
        error: {
          status: 400 /* bad request */,
          statusText: 'API error',
          message: error,
        },
      };
    }
  }

  return result;
}
export interface ErrorResponse {
  status: number;
  statusText: string;
  data: ErrorData;
  config: RequestConfig;
}

interface ErrorData {
  error: string;
  message: string;
}

interface RequestConfig {
  method: string;
  headers: Headers;
  data: unknown;
  url: string;
  retry: number;
  hideFromInspector: boolean;
}

export interface SimpleError {
  status: number;
  statusText: string;
  message: string;
}
export type APIError = SimpleError | z.ZodError<Json>;

export function isZodError(e: APIError): e is z.ZodError<Json> {
  return (e as unknown as { status: undefined }).status === undefined;
}

export type APIResponse<T> =
  | {
      success: false;
      error: APIError;
    }
  | { success: true; data: Omit<T, 'error'> };
