import React from 'react';

import { AppPlugin, PluginExtensionPoints } from '@grafana/data';
import { LoadingPlaceholder } from '@grafana/ui';

import type {
  AppRootProps,
  OnCallPluginConfigPageProps,
  OnCallPluginMetaJSONData,
} from '@grafana-oncall-app/app-types';
import { IRM_TAB } from '@grafana-oncall-app/helpers/consts';
import { isCurrentGrafanaVersionEqualOrGreaterThan } from '@grafana-oncall-app/helpers/helpers';
import { addExtensionComponent, getIsIrmPluginPresent } from '@grafana-irm/core';

const MobileAppConnection = React.lazy(
  () => import('@grafana-oncall-app/containers/MobileAppConnection/MobileAppConnection')
);
const GrafanaPluginRootPage = React.lazy(() => import('plugin/GrafanaPluginRootPage'));

function LazyMobileAppConnection() {
  return (
    <React.Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
      <MobileAppConnection />
    </React.Suspense>
  );
}

function LazyGrafanaPluginRootPage(props: AppRootProps) {
  return (
    <React.Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
      <GrafanaPluginRootPage {...props} />
    </React.Suspense>
  );
}

const LazyPluginConfigPage = React.lazy(
  () => import('@grafana-oncall-app/containers/PluginConfigPage/PluginConfigPage')
);

const plugin = new AppPlugin<OnCallPluginMetaJSONData>().setRootPage(LazyGrafanaPluginRootPage).addConfigPage({
  title: 'Configuration',
  icon: 'cog',
  body: LazyPluginConfigPage as unknown as React.ComponentClass<OnCallPluginConfigPageProps, unknown>,
  id: 'configuration',
});

if (isUseProfileExtensionPointEnabled()) {
  addExtensionComponent<OnCallPluginMetaJSONData>(plugin, {
    title: IRM_TAB,
    description: 'IRM settings',
    component: LazyMobileAppConnection,
    targets: ['grafana/user/profile/tab'],
  });
}

function isUseProfileExtensionPointEnabled(): boolean {
  return (
    isCurrentGrafanaVersionEqualOrGreaterThan({ minMajor: 10, minMinor: 3 }) &&
    'configureExtensionComponent' in plugin &&
    PluginExtensionPoints != null &&
    'UserProfileTab' in PluginExtensionPoints &&
    !getIsIrmPluginPresent()
  );
}

export { plugin };
