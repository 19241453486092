import { AppEvents } from '@grafana/data';
// https://github.com/grafana/irm/issues/278
// @ts-expect-error ingested from OnCall to show notifications, should be removed in favor to emit.ts
import appEvents from 'grafana/app/core/app_events';

import { sentenceCase } from 'change-case';

export const formatBackendError = (
  payload: string | Record<string, string>,
): string =>
  typeof payload === 'string'
    ? payload
    : Object.keys(payload)
        .map((key) => `${sentenceCase(key)}: ${payload[key]}`)
        .join('\n');

export function openNotification(message: React.ReactNode): void {
  appEvents.emit(AppEvents.alertSuccess, [message]);
}

export function openErrorNotification(message: string): void {
  appEvents.emit(AppEvents.alertError, [message]);
}

export function openWarningNotification(message: string): void {
  appEvents.emit(AppEvents.alertWarning, [message]);
}
