export type DevLog = (check: boolean, message: string) => asserts check;

/// Logs a message if the check is true.
/// They are not enabled in production.
export const warning: DevLog = (check, message) => {
  if (!check && typeof console !== 'undefined') {
    console.warn('WARN: %o', message);
  }
};

/// Log an error, break in debugger in development. In production, it will throw an error.
export const error: DevLog = (check, message) => {
  if (!check) {
    if (process.env.NODE_ENV === 'test') {
      console.error('ERROR: %o', message);
      throw new Error(message);
    }
    if (process.env.NODE_ENV === 'development') {
      if (typeof console !== 'undefined') {
        console.error('ERROR: %o', message);
      }
      // eslint-disable-next-line no-debugger
      debugger;
    }
    throw new Error(message);
  }
};

/// Assertions are conditions that should be true at all times,
/// Note that they produce warnings in production, which is useful in situations where
/// versions have changed between builds, but we have to try to be compatible.
///
/// in other words, assertions can be *temporarily* out of sync with the rest of the code.
/// but these should be considered very serious bugs.
export const assert: DevLog = process.env.NODE_ENV !== 'production' ? error : warning;

/// Invariants are conditions that must be true at all times,
/// in development, we break in the debugger, but in production,
/// we throw an error. Invariants are never violated.
export const invariant: DevLog = error;
