import { assert } from './devlog';

/**
 * Utility for compile-time checking of an if-else or case statement is
 * inexhaustive when iterating over conditions. The checked value should be
 * passed to `assertUnreachable(switchExpr)` as the final check. If any value was missed
 * a **type error** will occur.
 */
export default function assertUnreachable(x = null!): never {
  assert(false, `Non-exhaustive switch: case ${(x as null | { toString: () => string })?.toString()} was not handled.`);
}
