import {
  type Faro,
  initializeFaro,
  LogLevel,
  getWebInstrumentations,
  type BrowserConfig,
  type TransportItem,
  type APIEvent,
} from '@grafana/faro-web-sdk';

import type { AxiosResponse } from 'axios';

import { safeJSONStringify } from './string';

interface Config {
  url: string;
  isCloud: boolean;
  pluginId: string;
  pluginVersion: string;
}

class BaseFaroHelper {
  public faro?: Faro = undefined;
  private config?: {
    url: string;
    isCloud: boolean;
    pluginId: string;
    pluginVersion: string;
  } = undefined;

  public initializeFaro(config: Config) {
    if (this.faro != null || !config.isCloud) {
      return undefined;
    }

    this.config = config;

    try {
      const faroOptions: BrowserConfig = {
        url: this.config.url,
        isolate: true,
        instrumentations: [
          ...getWebInstrumentations({
            captureConsoleDisabledLevels: [LogLevel.TRACE, LogLevel.ERROR],
          }),
        ],
        app: {
          name: this.config.pluginId,
          version: this.config.pluginVersion,
        },
        sessionTracking: {
          persistent: true,
        },
        beforeSend: (event: TransportItem<APIEvent>) => {
          if (
            this.config?.pluginId != null &&
            (event.meta.page?.url ?? '').includes(this.config.pluginId) != null
          ) {
            return event;
          }
          return null;
        },
      };

      this.faro = initializeFaro(faroOptions);

      this.faro.api.pushLog([
        `Faro was initialized for ${this.config.pluginId}`,
      ]);
    } catch (ex) {
      console.error(ex);
    }

    return this.faro;
  }

  public pushReactError = (error: Error) => {
    this.faro?.api.pushError(error, { context: { type: 'react' } });
  };

  public pushNetworkRequestEvent = (config: {
    method: string;
    url: string;
    body: string;
  }) => {
    this.faro?.api.pushEvent('Request sent', config);
  };

  public pushFetchNetworkResponseEvent = ({
    name,
    res,
    method,
  }: {
    name: string;
    res: Response;
    method: string;
  }) => {
    this.faro?.api.pushEvent(name, {
      method,
      url: res.url,
      status: `${res.status}`,
      statusText: `${res.statusText}`,
    });
  };

  public pushFetchNetworkError = ({
    res,
    responseData,
    method,
  }: {
    res: Response;
    responseData: unknown;
    method: string;
  }) => {
    this.faro?.api.pushError(new Error(`Network error: ${res.status}`), {
      context: {
        method,
        type: 'network',
        url: res.url,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        data: `${safeJSONStringify(responseData)}`,
        status: `${res.status}`,
        statusText: `${res.statusText}`,
        timestamp: new Date().toUTCString(),
      },
    });
  };

  public pushAxiosNetworkResponseEvent = ({
    name,
    res,
  }: {
    name: string;
    res?: AxiosResponse;
  }) => {
    this.faro?.api.pushEvent(name, {
      url: res?.config?.url ?? '',
      status: `${res?.status}`,
      statusText: `${res?.statusText}`,
      method: res?.config?.method?.toUpperCase() ?? '',
    });
  };

  public pushAxiosNetworkError = (res?: AxiosResponse) => {
    this.faro?.api.pushError(new Error(`Network error: ${res?.status}`), {
      context: {
        url: res?.config?.url ?? '',
        type: 'network',
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        data: `${safeJSONStringify(res?.data)}`,
        status: `${res?.status}`,
        statusText: `${res?.statusText}`,
        timestamp: new Date().toUTCString(),
      },
    });
  };
}

export const FaroHelper = new BaseFaroHelper();
