export class LocalStorageHelper {
  public static setItem(key: string, value: unknown): void {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.warn('Local Storage is not available');
    }
  }

  public static getItem<T extends string | boolean>(
    key: string,
  ): T | undefined {
    try {
      const raw = localStorage.getItem(key);
      return raw != null ? (JSON.parse(raw) as T) : undefined;
    } catch (e) {
      console.warn('Local Storage is not available');
      return undefined;
    }
  }

  public static removeItem(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.warn('Local Storage is not available');
    }
  }

  public static setItemWithTTL(key: string, value: unknown, ttl: number): void {
    const now = new Date();

    const item = {
      value,
      expiry: now.getTime() + ttl,
    };
    try {
      localStorage.setItem(key, JSON.stringify(item));
    } catch (e) {
      console.warn('Local Storage is not available');
    }
  }

  public static getItemWithTTL<T>(key: string): T | undefined {
    let itemStr;
    try {
      itemStr = localStorage.getItem(key);
    } catch (e) {
      console.warn('Local Storage is not available');
      return undefined;
    }
    if (itemStr == null) {
      return undefined;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return undefined;
    }
    return item.value as T;
  }
}
