import {
  alt_sc as altSc,
  apply,
  buildLexer,
  expectEOF,
  expectSingleResult,
  opt_sc as optSc,
  list_sc as listSc,
  rep_sc as repSc,
  seq,
  tok,
} from 'typescript-parsec';

import assertUnreachable from './assertUnreachable';

/**
 * Represents a simple filter which can be either a `KeyValueFilter` or a `TextFilter`.
 *
 * - `KeyValueFilter` is used for filters with a specific key and value, optionally negated.
 * - `TextFilter` is used for free text filters without specific keys.
 */
export type SimpleFilter = KeyValueFilter | TextFilter;

export interface KeyValueFilter {
  kind: 'kv';
  negate: boolean;
  type: string;
  value: string;
}

export interface TextFilter {
  kind: 'free';
  value: string;
}

enum TokenKind {
  Whitespace,
  Negation,
  Colon,
  StringKeyword,
  FreeString,
}

export function isKeyValueFilter(filter: SimpleFilter): filter is KeyValueFilter {
  return filter.kind === 'kv';
}

const lexer = buildLexer([
  [
    true,
    /^(assignee|assigneeemail|author|authoremail|incident|incidentSeverityID|incidentStatus|incidentLabel|kind|status)\b/g,
    TokenKind.StringKeyword,
  ],
  [true, /^-/g, TokenKind.Negation],
  [true, /^:/g, TokenKind.Colon],
  [true, /^[^-:\s]+/g, TokenKind.FreeString],
  [true, /^\s+/gu, TokenKind.Whitespace],
]);

const parseFreeWithColon = apply(
  repSc(altSc(tok(TokenKind.FreeString), tok(TokenKind.Negation), tok(TokenKind.Colon), tok(TokenKind.StringKeyword))),
  (tokens) => {
    let result = '';
    for (const token of tokens) {
      result += token.text;
    }
    return { kind: 'free', value: result } as TextFilter;
  }
);

const parseStringFilter = apply(
  seq(optSc(tok(TokenKind.Negation)), tok(TokenKind.StringKeyword), tok(TokenKind.Colon), parseFreeWithColon),
  ([neg, keyword, _, str]) => {
    return { kind: 'kv', negate: neg != null, type: keyword.text, value: str.value } as KeyValueFilter;
  }
);

/// We also allow free text at the top level
const parseFilter = listSc(altSc(parseStringFilter, parseFreeWithColon), tok(TokenKind.Whitespace));

function printStringFilter(filter: KeyValueFilter): string {
  return `${filter.negate ? '-' : ''}${filter.type}:${filter.value ?? ''}`;
}

function printFreeFilter(filter: TextFilter): string {
  return filter.value;
}

function prettyPrintFilter(filter: SimpleFilter): string {
  switch (filter.kind) {
    case 'kv':
      return printStringFilter(filter);
    case 'free':
      return printFreeFilter(filter);
    default:
      return assertUnreachable(filter);
  }
}

/**
 * Parses a string containing filters into an array of `SimpleFilter` objects.
 *
 * This function takes a single string as input, where each filter is separated by whitespace.
 * Filters can be either key-value pairs (e.g., "status:open") or free text (e.g., "urgent").
 * Key-value pairs can be negated by prefixing the key with a "-" (e.g., "-status:closed").
 *
 * The function returns a readonly array of `SimpleFilter` objects, each representing a parsed filter.
 * If the input string is empty or only contains whitespace, an empty array is returned.
 *
 * @param filters The string containing the filters to be parsed.
 * @returns A readonly array of `SimpleFilter` objects representing the parsed filters.
 */
export function parseSimpleFilters(filters: string): ReadonlyArray<SimpleFilter> {
  const lexed = lexer.parse(filters.trim());
  if (lexed == null) {
    return [];
  }
  return expectSingleResult(expectEOF(parseFilter.parse(lexed)));
}

/**
 * Converts an array of `SimpleFilter` objects into a single string representation.
 * Each filter is separated by a space in the resulting string.
 *
 * @param filters An array of `SimpleFilter` objects to be converted into a string.
 * @returns A string representation of the filters, with each filter separated by a space.
 */
export function simpleFiltersAsString(filters: ReadonlyArray<SimpleFilter>): string {
  return filters.map(prettyPrintFilter).join(' ');
}
